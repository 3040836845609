<template>
    <page>
        <div>
            <div class="f_flex f_a_c f_j_sb" style="position: sticky; top: 0; z-index: 8; background: #fff">
                <div class="f_g1">
                    <van-search shape='round' v-model="word" @input="changeFn" placeholder="搜索"></van-search>
                </div>
                <div class="buildTxt theme_c" @click="$router.back()">取消</div>
            </div>
            
            <div v-if='list.length' style="padding: 0 14px">
                <div v-if='dealList.normal.length'>
                    <div v-for='(item, index) in dealList.normal' :key='index'>
                        <div v-if='item.groupList.length'>
                            <div class="buileTitle">{{ item.company.companyName }}</div>
                            <!-- <group-item
                              v-for='(ii, kk) in item.groupList'
                              :key='kk'
                              :companyId='item.company.companyId'
                              :info='ii'
                              :companyInfo='item.company'
                              @pick='goGroupDetailFn(item, ii)'
                              :disable='ii.groupStatus == 1'
                              :titleStyle="{color: ii.groupStatus == 2 ? '#9B9B9B' : '#333'}"
                              :showIcon='ii.topStatus === 1'>
                            </group-item> -->
                            <div v-for='(ii, key) in item.groupList' :key='key'
                                 @dblclick.stop="goGroupDetailFn(item, ii)">
                                <van-swipe-cell
                                    stop-propagation
                                    :class="{top: ii.topStatus === 1}">
                                    <group-item :companyInfo='item.company' :companyId='item.company.companyId'
                                                :info='ii' :showIcon='ii.topStatus === 1'></group-item>
                                    <template #right>
                                        <van-button
                                            style="width: 100%; height: 100%"
                                            @click='setTopFn(ii)'
                                            square
                                            type="info"
                                            :text="ii.topStatus === 1 ? '取消置顶' : '置顶'"/>
                                    </template>
                                </van-swipe-cell>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if='dealList.close.length && hasGroup(dealList.close)'>
                    <div style="font-size: 15px; margin: 10px 0; margin-top: 20px">关闭的群</div>
                    <div v-for='(item, index) in dealList.close' :key='index'>
                        <div v-if='item.groupList.length'>
                            <div class="buileTitle">{{ item.company.companyName }}</div>
                            <group-item
                                v-for='(ii, kk) in item.groupList'
                                :key='kk'
                                :companyId='item.company.companyId'
                                :info='ii'
                                :companyInfo='item.company'
                                :disable='ii.groupStatus == 1'
                                :titleStyle="{color: ii.groupStatus == 2 ? '#9B9B9B' : '#333'}"
                                :showIcon='ii.topStatus === 1'>
                            </group-item>
                        </div>
                    </div>
                </div>
            </div>
            <empty v-if='isEmpty' description='没有内容哦'/>
        </div>
    </page>
</template>

<script>
import {Search, Empty} from 'vant';
import GroupItem from './groupItem.vue'
import groupApi from '@/api/group'
import {SwipeCell, Button} from 'vant';
import {mapGetters, mapActions} from 'vuex';

export default {
    components: {
        'van-search': Search,
        GroupItem,
        Empty,
        'van-swipe-cell': SwipeCell,
        'van-button': Button,
    },
    data: () => ({
        list: [],
        result: {},
        word: ''
    }),
    computed: {
        ...mapGetters ([
            'get_groupSearchList'
        ]),
        isEmpty () {
            const list = this.list
            let b = true
            if (list.length) {
                for (let i = 0; i < list.length; i++) {
                    const item = list[i]
                    const gi = item.groupList.length;
                    if (gi) {
                        b = false;
                        break;
                    }
                }
                return b;
            } else {
                return b
            }
        },
        
        dealList () {
            const list = this.list
            const obj = {
                normal: [],
                close: []
            }
            if (list.length) {
                list.forEach (item => {
                    const group = item.groupList
                    const company = item.company
                    const isNormal = group.filter (ii => ii.groupStatus == 1)
                    const isClose = group.filter (ii => ii.groupStatus == 2)
                    obj.normal.push ({
                        company,
                        groupList: isNormal
                    })
                    obj.close.push ({
                        company,
                        groupList: isClose
                    })
                })
            }
            return obj
        }
    },
    methods: {
        ...mapActions ([
            'set_groupSearchList'
        ]),
        setTopFn (item) {
            groupApi.setTop ({
                groupId: item.groupId,
                topStatus: item.topStatus == 1 ? 2 : 1
            }).then (res => {
                if (res.data) {
                    this.getData ()
                }
            })
        },
        hasGroup (list) {
            let b = true
            b = list.some (item => item.groupList.length)
            return b
        },
        changeFn (n) {
            if (!n) {
                this.init ()
                return;
            }
            this.$global.debounce (() => {
                this.init ()
                this.getData ()
            })
        },
        goGroupDetailFn (item, ii) {
            if (this.$global.ua ().isTOUCH) return;
            if (ii.groupStatus == 2) return;
            const companyId = item.company.companyId
            const groupId = ii.groupId
            this.$router.push (`/group/detail?companyId=${companyId}&groupId=${groupId}&keyword=${item.company.companyKeyword}`)
        },
        getData () {
            this.list = []
            if (this.word) {
                groupApi.groupList ({
                    searchWord: this.word,
                    companyId: JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
                }).then (res => {
                    if (res.data) {
                        this.list.push (...res.data.companyGroupList)
                        this.set_groupSearchList (this.list)
                    }
                })
            }
        },
        init () {
            this.$set (this, 'list', [])
        }
    },
    mounted () {
        if (!(this.get_groupSearchList && this.get_groupSearchList.length)) {
                this.word = ''
                this.init ()
            }
    },

    beforeRouteEnter (to, from, next) {
      next((vm) => {
        if (from.name != 'postChoose') {
          if (!(vm.get_groupSearchList && vm.get_groupSearchList.length)) {
                vm.word = ''
                vm.init ()
            }
        
        }
      })
    },
    
}
</script>

<style lang="less" scoped>
.buildTxt {
    font-size: 15px;
    margin-left: 11px;
    margin-right: 14px;
}

.buileTitle {
    font-size: 15px;
    color: #434343;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 21px;
}

.searchPanel {
    background: #F1F1F1;
    border-radius: 30px;
    padding: 0 18px;
    box-sizing: border-box;
    height: 34px;
}

.top {
    background: #F6F6F6;
}
</style>
